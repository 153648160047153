import React from "react";
import { Container, Row, Col, Card, CardHeader, CardBody, Button, ButtonGroup } from "shards-react";

import PageTitle from "../components/common/PageTitle";
import { DELETE_REQUEST, GET_REQUEST, PATCH_REQUEST, POST_REQUEST } from "../lib/request";
import ActionButton from "../components/ActionButton";
import { toast } from "react-toastify";
import Loader from "../components/loader";
import Pagination from "../components";
import CreateBatch from "../components/batch/createBatch";

const ClassBatch = () => {

    const [selected, setSelected] = React.useState(0);
    const [selectedScreen, setSelectedScreen] = React.useState(0);
    const [name, setName] = React.useState("");
    const [image, setImage] = React.useState(null);
    const [loader, setLoader] = React.useState(0);
    const [imageBinary, setImageBinary] = React.useState(null);
    const [batchId, setBatchId] = React.useState("");
    const [batch, setBatch] = React.useState([]);
    const [skip, setSkip] = React.useState(0);
    const [page, setPage] = React.useState(1);
    const [totalCount, setTotalResults] = React.useState(0);

    const GET_BATCH = () => {
        setLoader(1)
        GET_REQUEST("/batch?limit=10&asc=1&skip=" + skip)
            .then((response) => {
                if (response && response.data && response.data.batch) {
                    setBatch(response.data.batch);
                    setTotalResults(response.data.totalResults);
                }
                if (response.status === 204) {
                    setBatch([]);
                }
                setLoader(0)
            })
            .catch((error) => {
                console.log('Error in fetching data', error);
                setLoader(0)
            })
    }

    React.useEffect(() => {
        if (!selected) {
            GET_BATCH()
            setBatchId("");
            setName("");
            setImage("");
        }
    }, [selected]);

    const CreateNewBatch = () => {
        if (!name || !image) {
            toast.error("Name and image are manadatory!")
        }
        setLoader(1)
        const formData = new FormData();
        formData.append('image', imageBinary);
        POST_REQUEST("/upload", formData).then((response) => {
            if (response.status === 201) {
                if (response.data && response.data.path && response.data.path.filename) {
                    POST_REQUEST("/batch", { batch: { status: 1, name, image: response.data.path.filename, imageUrl: response.data.imageUrl } })
                        .then((d) => {
                            toast.success("Batch Created Successfully!");
                            GET_BATCH()
                            setSelected(0)
                        }).catch((e) => {
                            setSelected(1)
                        })
                }
            }
            setLoader(0);
        }).catch((error) => {
            console.error('Error While Uploading Image', error);
            setLoader(1)
            toast.error("Error While Uploading Image");
        })
    }

    const UpdateBatch = async () => {
        if (!name || !image || selected !== 2 || !batchId) {
            toast.error("name and image are manadatory field!")
            return;
        }
        setLoader(1);
        let batchData = { name }
        if (imageBinary) {
            const formData = new FormData();
            formData.append("image", imageBinary);
            await POST_REQUEST("/upload", formData).then((response) => {
                if (response.data && response.data.path && response.data.path.filename) {
                    batchData.image = response.data.path.filename;
                    batchData.imageUrl = response.data.imageUrl
                }
            })
                .catch((error) => {
                    toast.error("Failed to upload new image");
                    setLoader(0);
                    return;
                })
        }
        PATCH_REQUEST("/batch", { batchId, batch: batchData })
            .then((d) => {
                toast.success("Batch Updated Successfully!")
                GET_BATCH()
                setSelected(0)
            }).catch((e) => {
                setSelected(1)
            })
        setLoader(0)
    }

    const deleteBatch = async (id) => {
        if (!batch && !batch.length) {
            return;
        }
        const batData = batch.find((d) => d._id === id);
        if (!batData) {
            toast.error("No Such Batch!");
            return;
        }
        setLoader(1)
        DELETE_REQUEST("/batch", { batchId: id })
            .then((d) => {
                toast.success("Batch Deleted Successfully!");
                setLoader(0);
                GET_BATCH()
            }).catch((e) => {
                setLoader(0);
                console.error('Error while delete')
            })
    }


    const editBatch = async (id) => {
        if (!batch && !batch.length) {
            return;
        }
        const batData = batch.find((d) => d._id === id);
        if (!batData) {
            toast.success("No Such Batch Found!")
            return;
        }
        setBatchId(id);
        setName(batData.name);
        setImage(batData.imageUrl);
        setSelected(2);
    }

    const onImageChange = event => {
        if (event.target.files && event.target.files[0]) {
            let img = event.target.files[0];
            setImageBinary(img);
            setImage(URL.createObjectURL(img))
        }
    };

    const PaginationHandler = (data) => {
        setSkip((data - 1) * 10);
        setPage(data);
        // const skip = (data - 1) * 10
    }

    React.useEffect(() => {
        GET_BATCH()
    }, [skip])

    if (selected) {
        return (
            <Container fluid className="main-content-container px-4 pb-4">
                {/* Page Header */}
                <Row noGutters className="page-header py-2">
                    <PageTitle sm="4" title="" subtitle="" className="text-sm-left" />
                </Row>

                <Row>
                    {/* Editor */}
                    <Col>
                        <Card>
                            <CardHeader className="d-flex border-bottom">
                                <ButtonGroup>
                                    <Button onClick={() => { setSelected(0); setSelectedScreen(0) }} className={`mr-2 pr-4 ${selectedScreen === 0 ? 'btn-primary' : 'btn-outline-primary'}`}>View Batches</Button>
                                    <Button onClick={() => { setSelected(1); setSelectedScreen(1) }} className={`ml-2 pr-4 ${selectedScreen === 1 ? 'btn-primary' : 'btn-outline-primary'}`}>Add Batch</Button>
                                </ButtonGroup>
                            </CardHeader>
                            <CreateBatch
                                setName={setName}
                                name={name}
                                image={image}
                                onImageChange={onImageChange}
                                header={selected === 2 ? "Update Batch Data" : "Add New Batch" }
                            />
                            {/* <PublishDraftGroup status={status} onClick={setStatus} /> */}
                            <Button onClick={() => selected === 2 ? UpdateBatch() : CreateNewBatch()} theme="primary" >Submit</Button>
                        </Card>
                    </Col>
                </Row>
                <Loader start={loader} />
            </Container>
        )
    }
    else {
        return (
            <Container fluid className="main-content-container px-4">
                {/* Page Header */}
                <Row noGutters className="page-header py-2">
                    <PageTitle sm="4" title="" subtitle="" className="text-sm-left" />
                </Row>

                {/* Default Light Table */}
                <Row>
                    <Col>
                        <Card small className="mb-4">
                            <CardHeader className="d-flex border-bottom">
                                <ButtonGroup>
                                    <Button onClick={() => { setSelected(0); setSelectedScreen(0) }} className={`mr-2 pr-4 ${selectedScreen === 0 ? 'btn-primary' : 'btn-outline-primary'}`}>View Batches</Button>
                                    <Button onClick={() => { setSelected(1); setSelectedScreen(1) }} className={`ml-2 pr-4 ${selectedScreen === 1 ? 'btn-primary' : 'btn-outline-primary'}`}>Add Batch</Button>
                                </ButtonGroup>
                            </CardHeader>
                            <CardBody className="p-0 pb-3 table-responsive-md">
                                <table className="table mb-0">
                                    <thead className="bg-light">
                                        <tr>
                                            <th scope="col" className="border-0">
                                                #
                                            </th>
                                            <th scope="col" className="border-0">
                                                Image
                                            </th>
                                            <th scope="col" className="border-0">
                                                Batch Name
                                            </th>
                                            <th scope="col" className="border-0">
                                                Created At
                                            </th>
                                            <th scope="col" className="border-0">
                                                Actions
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            batch && batch.length ?
                                                batch.map((data, index) =>
                                                    <tr key={index}>
                                                        <td>
                                                            {(page - 1) * 10 + index + 1}.
                                                        </td>
                                                        <td>
                                                            <img
                                                                className="rounded-circle"
                                                                src={data.imageUrl}
                                                                alt={data.name}
                                                                width="60"
                                                                height="40"
                                                                onError={(e) => e.currentTarget.src = "https://cbcclasses.com/logo_new.png"} // Replace with your fallback image URL
                                                            />

                                                        </td>
                                                        <td>{data.name}</td>
                                                        <td>
                                                            {new Date(data.createdAt).toDateString()}
                                                        </td>
                                                        <td>
                                                            <ActionButton
                                                                delete={deleteBatch}
                                                                edit={editBatch}
                                                                id={data._id}
                                                            />
                                                        </td>
                                                    </tr>
                                                ) : <tr></tr>
                                        }
                                    </tbody>
                                </table>
                            </CardBody>
                        </Card>
                        <Row noGutters className="d-flex justify-content-end">
                            <Pagination
                                initialPage={page}
                                totalCount={totalCount}
                                onChangePage={PaginationHandler}
                            />
                            <PageTitle sm="4" title={totalCount || ""} subtitle="" className="d-flex justify-content-end text-sm-right" />
                        </Row>
                    </Col>
                </Row>
                <Loader start={loader} />
            </Container>
        )
    }
};

export default ClassBatch;
