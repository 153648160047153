import React from "react";
import PropTypes from "prop-types";
import {
    Card,
    CardHeader,
    CardBody,
    Form,
    FormGroup,
    FormInput,
    FormSelect,
    DatePicker
} from "shards-react";

const CreateStudents = ({
    header,
    batch,
    selectedDate,
    setSelectedDate,
    fee,
    setFee,
    dfee,
    setDFee,
    batchId,
    setBatchId,
    name,
    setName,
    phone,
    setPhone,
    fName,
    setfName,
    image,
    onImageChange
}) => {

    const SelectBatch = (name) => {
        const batchInfo = batch.find((cat) => cat.name === name);
        if (batchInfo && batchInfo._id) {
            setBatchId(batchInfo._id);
        } else {
            setBatchId("");
        }
    }

    return (
        <Card small className="h-100">
            <CardHeader className="border-bottom">
                <h6 className="m-0">{header}</h6>
            </CardHeader>

            <CardBody>
                <Form className="d-flex flex-column">
                    <FormGroup>
                        <label htmlFor="batch">Batch</label>
                        <FormSelect onChange={(event) => SelectBatch(event.target.value)} id="batch">
                            <option>Choose ...</option>
                            {
                                batch && batch.length && batch.map((cat, i) => (
                                    <option selected={batchId === cat._id} key={i} id={cat._id}>{cat.name}</option>
                                ))
                            }
                        </FormSelect>
                    </FormGroup>

                    <FormGroup>
                        <label htmlFor="name">Student Name</label>
                        <FormInput value={name} onChange={(e) => setName(e.target.value)} id="name" placeholder="Student Name" />
                    </FormGroup>

                    <FormGroup>
                        <label htmlFor="phone">Phone Number</label>
                        <FormInput type="tel" value={phone} onChange={(e) => setPhone(e.target.value)} id="phone" placeholder="Student Phone" />
                    </FormGroup>

                    <FormGroup>
                        <label htmlFor="fatherName">Father Name</label>
                        <FormInput value={fName} onChange={(e) => setfName(e.target.value)} id="fatherName" placeholder="Father Name" />
                    </FormGroup>

                    <FormGroup>
                        <label htmlFor="monthlyFee">Monthly Fee</label>
                        <FormInput value={fee} onChange={(e) => setFee(e.target.value)} id="monthlyFee" placeholder="Monthly Fee" />
                    </FormGroup>

                    <FormGroup>
                        <label htmlFor="discountedFee">Discounted Fee</label>
                        <FormInput value={dfee} onChange={(e) => setDFee(e.target.value)} id="discountedFee" placeholder="Discounted fee" />
                    </FormGroup>

                    <FormGroup className="d-flex flex-column">
                        <label htmlFor="dateOfJoining">Date of Joining</label>
                        <DatePicker
                            selected={selectedDate ? new Date(selectedDate) : new Date()}
                            onChange={date => { setSelectedDate(date) }}
                            dateFormat="dd/MM/yyyy"
                            placeholderText="Date Of Joining"
                            className="form-control"
                            id="dateOfJoining"
                        />
                    </FormGroup>

                    <FormGroup>
                        <label htmlFor="studentImage">Student Image</label>
                        {
                            image &&
                            <div className="mb-3 mx-auto">
                                <img
                                    className="rounded-circle"
                                    src={image}
                                    alt={`${name}_image`}
                                    width="110"
                                />
                            </div>
                        }
                        <FormInput type="file" name="image" onChange={onImageChange} id="studentImage" />
                    </FormGroup>
                </Form>
            </CardBody>
        </Card>
    );
}

CreateStudents.propTypes = {
    header: PropTypes.string
};

CreateStudents.defaultProps = {
    header: "ADD Student"
};

export default CreateStudents;