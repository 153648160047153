import React from 'react';
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
    CardText,
    Button,
    Form,
    FormInput,
    FormTextarea
} from 'shards-react';
import Carousel from '../components/common/Carousel';
import EnhancedNavbar from '../components/common/CustomNavbar';
import { getFromLocalStorage } from '../lib/helper';
import { Constants } from '../flux';
import { POST_REQUEST } from '../lib/request';
import { toast, ToastContainer } from 'react-toastify';

const HomePage = () => {

    const [activeLink, setActiveLink] = React.useState('home');
    const [fullName, setName] = React.useState('');
    const [email, setEmail] = React.useState('');
    const [phone, setPhone] = React.useState('');
    const [message, setMessage] = React.useState('');
    const [collapseOpen, setCollapseOpen] = React.useState(false);
    const [disabled, setDisabled] = React.useState(false);
    const toggleNavbar = (e) => {
        e.preventDefault();
        setCollapseOpen(!collapseOpen);
    };

    const handleNavClick = (link) => {
        setActiveLink(link);
    };

    const submitForm = (e) => {
        e.preventDefault();
        POST_REQUEST("/contactus", {name: fullName, phone, email, message, status: 1, createdAt: new Date().getTime()}).then(() => {
            toast.success("Your Message Has Been Recorded!");
        }).catch(() => {
            toast.success("Your Message Has Been Recorded!")
        });
        setDisabled(true)
    }

    const isAuthenticated = getFromLocalStorage(Constants.IS_AUTHENTICATED);

    return (
        <div className="cbc-commerce-classes">
            <ToastContainer />
            <EnhancedNavbar
                isAuthenticated={isAuthenticated}
                activeLink={activeLink}
                handleNavClick={handleNavClick}
                toggleNavbar={toggleNavbar}
                collapseOpen={collapseOpen}
                setCollapseOpen={setCollapseOpen}
            />

            <Carousel />

            <Container className="mt-5" id="about">
                <Row>
                    <Col>
                        <h2 className="text-center mb-4 font-weight-bold">About Us</h2>
                        <Card>
                            <CardBody>
                                <p className="text-center font-italic">
                                    CBC Coaching Institute is committed to providing quality education and fostering an environment where students can thrive. Under the mentorship of Shubham Sir, we focus on holistic development and ensuring each student reaches their full potential. With a strong emphasis on understanding and application, our institute offers courses across various streams, tailored to meet the needs of every learner.
                                </p>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>

            <Container className="mt-5" id="subjects">
                <h2 className="text-center mb-4 font-weight-bold">Our Subjects</h2>
                <Row>
                    {[
                        "Shubham Sir’s Accounts classes provide a comprehensive understanding of financial accounting and management. With a focus on foundational principles, students are guided through the intricacies of bookkeeping, balance sheets, and financial statements",
                        "Unlock the World of Economic Thought In Shubham Sir’s Economics classes, students delve into the core principles that drive economies and influence global markets. From microeconomics to macroeconomics, each topic is explored in depth, with an emphasis on real-world applications",
                        "Build a Strong Foundation in Business Management Business Studies with Shubham Sir covers everything from the fundamentals of management to the strategies employed by successful businesses"].map((subject, index) => (
                            <Col md={4} key={index} className="mb-4">
                                <Card>
                                    <CardBody>
                                        <CardTitle className="font-weight-bold">{index + 1 === 1 ? "Account" : index + 1 === 2 ? "Economics" : "Business Studies"}</CardTitle>
                                        <CardText className="font-italic">
                                            {subject}
                                        </CardText>
                                    </CardBody>
                                </Card>
                            </Col>
                        ))}
                </Row>
            </Container>

            <Container fluid className="bg-light py-5 mt-5" id="testimonials">
                <Container>
                    <h2 className="text-center mb-4 text-bold font-weight-bold">Student Testimonials</h2>
                    <Row>
                        {[
                            "Shubham Sir concepts are easy-to-understand. His teaching in Business Studies has helped me grasp the subject in a way I never thought possible. His dedication to each student's success is evident in the way he patiently explains every detail, ensuring no one is left behind",
                            "I struggled with Accountancy until I joined Shubham Sir's classes. The way he connects theory to practical examples makes the subject not only easier but also more interesting. Thanks to him, my confidence in Accountancy has skyrocketed.",
                            "Economics was a subject I found challenging, but Shubham Sir made it one of my favorites. He simplifies complex economic theories and relates them to real-world scenarios, making the classes engaging and insightful. I highly recommend his coaching to anyone looking to excel in commerce"
                        ].map((_, index) => (
                            <Col md={4} key={index} className="mb-4">
                                <Card>
                                    <CardBody>
                                        <CardText className="font-italic">
                                            {_}
                                        </CardText>
                                        <div className="text-warning">
                                            ⭐ ⭐ ⭐ ⭐ ⭐
                                        </div>
                                        <CardText className="text-muted mt-2">- {index + 1 === 1 ? 'Rahul Singhania' : index + 1 === 2 ? "Ankit Srivastav" : "Talim Khan"}</CardText>
                                    </CardBody>
                                </Card>
                            </Col>
                        ))}
                    </Row>
                </Container>
            </Container>

            <Container className="mt-5 mb-5" id="contact">
                <h2 className="text-center mb-4 font-weight-bold">Contact Us</h2>
                <Row>
                    <Col md={6}>
                        <Form action="/contactus">
                            <FormInput placeholder="Your Name" className="mb-3" name="Name" value={fullName} onChange={(e) => setName(e.target.value)} />
                            <FormInput type="number" placeholder="Your Phone" className="mb-3" name="Name" value={phone} onChange={(e) => setPhone(e.target.value)} />
                            <FormInput placeholder="Your Email" className="mb-3" type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
                            <FormTextarea placeholder="Your Message" className="mb-3" name="Message" value={message} onChange={(e) => setMessage(e.target.value)} />
                            <Button disabled={disabled} onClick={(e) => submitForm(e)}>Send Message</Button>
                        </Form>
                    </Col>
                    <Col md={6}>
                        <h4 className='font-weight-bold'>Get in Touch</h4>
                        <p>📞 +91 7703935573</p>
                        <p>📩 cbccommerceclasses@gmail.com</p>
                        <p>📒 Follow us on social media</p>
                    </Col>
                </Row>
            </Container>

            <footer className="bg-dark text-white py-4 mt-5">
                <Container>
                    <Row>
                        <Col md={6}>
                            <h5 className='font-weight-bold'>CBC Commerce Classes</h5>
                            <p>Empowering students to achieve academic excellence since 2020.</p>
                        </Col>
                        <Col md={3}>
                            <h5 className='font-weight-bold'>Quick Links</h5>
                            <ul className="list-unstyled">
                                <li><a href="#home" className="text-white">Home</a></li>
                                <li><a href="#about" className="text-white">About</a></li>
                                <li><a href="#subjects" className="text-white">Subjects</a></li>
                                <li><a href="#contact" className="text-white">Contact</a></li>
                            </ul>
                        </Col>
                        <Col md={3}>
                            <h5 className='font-weight-bold'>Connect With Us</h5>
                            <ul className="list-unstyled">
                                <li><a target='_blank' href="https://www.facebook.com/cbccommerceclasses/" className="text-white">Facebook</a></li>
                                <li><a target='_blank' href="https://www.justdial.com/Delhi/Cbc-Commerce-Classes-Bhangel/011PXX11-XX11-220317182102-M8W8_BZDET" className="text-white">JustDial</a></li>
                                <li><a target='_blank' href="https://www.instagram.com/cbccommerceclasses/?hl=en" className="text-white">Instagram</a></li>
                            </ul>
                        </Col>
                    </Row>
                    <hr className="bg-light" />
                    <p className="text-center mb-0 font-italic">&copy; 2020 CBC Coaching Institute. All rights reserved.</p>
                </Container>
            </footer>
        </div>
    );
};

export default HomePage;