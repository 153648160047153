import React from "react";
import { Redirect } from "react-router-dom";

// Layout Types
import { DefaultLayout } from "./layouts";

import Batch from "./views/Batch";
import Students from "./views/Students";
import FeeOversight from "./views/FeeOversight";
import Feeds from "./views/Feeds";
import ContactUs from "./views/ContactUs";


export default [
  {
    path: "/",
    exact: true,
    layout: DefaultLayout,
    component: () => <Redirect to="/home" />
  },
  {
    path: "/batch",
    layout: DefaultLayout,
    component: Batch
  },
  {
    path: "/students",
    layout: DefaultLayout,
    component: Students
  },
  {
    path: "/fee",
    layout: DefaultLayout,
    component: FeeOversight
  },
  {
    path: "/feeds",
    layout: DefaultLayout,
    component: Feeds
  },
  {
    path: "/queries",
    layout: DefaultLayout,
    component: ContactUs
  },
];
