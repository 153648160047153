import React, { useState } from "react";
import { Card, Col, Container, Row, CardBody, Button, } from "shards-react";
import PageTitle from "../components/common/PageTitle";
import { toast } from "react-toastify";
import Loader from "../components/loader";
import { DELETE_REQUEST, GET_REQUEST } from "../lib/request";
import ActionButton from "../components/ActionButton";

const ContactUs = () => {

    const [loader, setLoader] = useState(false);
    const [queries, setQueries] = useState(false);


    const getAllContactUsQueries = () => {
        setLoader(1);
        GET_REQUEST(`/contactus`)
            .then((response) => {
                console.log('response.data', response.data);
                if (response && response.data && response.data.data) {
                    setQueries(response.data.data);
                }
                setLoader(0)
            })
            .catch((error) => {
                toast.error("Failed to Fetch Batches")
                console.log('Error in fetching data', error);
                setLoader(0)
            })
    };


    const deleteQueries = (e, id) => {
        e.preventDefault();
        setLoader(1);
        DELETE_REQUEST("/contactus", { queryId: id }).then(()=>{
            setTimeout(() => {
                getAllContactUsQueries()
            }, 1000)
        });
        setLoader(0)
        
    }

    React.useEffect(() => {
        getAllContactUsQueries()
    }, [])

    return (
        <Container fluid className="main-content-container px-4">
            <Row noGutters className="page-header py-2">
                <PageTitle sm="4" name="Contact Us Queries" subtitle="" className="text-sm-left" />
            </Row>

            <Row>
                <Col>
                    <Card small className="mb-4">
                        <CardBody className="p-0 pb-3 table-responsive-md">
                            <table className="table mb-0">
                                <thead className="bg-light">
                                    <tr>
                                        <th scope="col" className="border-0">
                                            Name
                                        </th>
                                        <th scope="col" className="border-0">
                                            Phone
                                        </th>
                                        <th scope="col" className="border-0">
                                            Email
                                        </th>
                                        <th scope="col" className="border-0">
                                            Message
                                        </th>
                                        <th scope="col" className="border-0">
                                            Query Date
                                        </th>
                                        <th scope="col" className="border-0">
                                            Action
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        queries && queries.length ?
                                            queries.map((data, index) =>
                                                <tr key={index}>
                                                    <td>{data.name}</td>
                                                    <td>{data.phone}</td>
                                                    <td>{data.email}</td>
                                                    <td>{data.message}</td>
                                                    <td>
                                                        {new Date(data.createdAt).toDateString()}
                                                    </td>
                                                    <td>
                                                        <Button onClick={(e) => deleteQueries(e, data._id)} outline size="sm" theme="danger" className="mr-1">
                                                            Delete
                                                        </Button>
                                                    </td>
                                                </tr>
                                            ) : <tr></tr>
                                    }
                                </tbody>
                            </table>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <Loader start={loader} />
        </Container>
    );
};

export default ContactUs;
